import React from "react"
import { Link } from "react-router-dom"

import { Anchor } from "@orchestrate-ui/core"

class ResourceLink extends React.Component {
  getLinkAction = (string, typeOverride = "") => {
    const links = [
      {
        regex: /(\d\d\d)([A-z]+)-([A-z])+(\d\d)*/,
        to: `/environments/${string}/overview`,
      },
      {
        regex: /(e-)(\d+)/,
        to: `/timeline/${string}`,
      },
      {
        regex: /unused/,
        typeOverride: "adminUserDetail",
        to: `/admin/users/${string}`,
      },
      {
        regex: /unused/,
        typeOverride: "adminGroupDetail",
        to: `/admin/groups/${string}`,
      },

      // could match other types in the future here
    ]

    const link = links.find(x => x.regex.exec(string) !== null || x.typeOverride === typeOverride)

    return (link) ? link : {}
  }

  render () {
    const { typeOverride, resourceKey, altText } = this.props
    const { action, to } = this.getLinkAction(resourceKey, typeOverride)

    return (
      <React.Fragment>
        {(action || to) &&
          <Link to={to} style={{ textDecoration: "none" }}>
            <Anchor anchorProps={{ onClick: action, ...this.props }} text={altText || resourceKey} {...this.props} />
          </Link>
        }
        {!(action || to) &&
          <span>{altText || resourceKey}</span>
        }
      </React.Fragment>
    )
  }
}

export default ResourceLink
