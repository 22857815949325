/* eslint camelcase: "off"*/
/* eslint no-param-reassign: "off"*/
/* eslint react/no-multi-comp: "off"*/
/* eslint react/no-unused-state: "off"*/
/* eslint react/no-did-update-set-state: "off"*/

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import _ from "lodash"
import keycode from "keycode"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import CodeIcon from "@material-ui/icons/Code"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"

import ReactMarkdown from "react-markdown"
import { Anchor } from "@orchestrate-ui/core"
import { UserAvatar } from "@orchestrate-ui/core"
import CodeBlock from "./CodeBlock"

import Dates from "utils/Dates"
import { isAuthorized } from "utils/Screens"
// import { putEventComment, deleteEventComment } from "state/actions"

const DynamicDescription = props => {
  const { count, root, singularSuffix, pluralSuffix, showZero } = props

  return (
    <React.Fragment>
      {(count === 0) && showZero &&
        `${count} ${root}${pluralSuffix}`
      }
      {(count === 1) &&
        `${count} ${root}${singularSuffix}`
      }
      {(count > 1) &&
        `${count} ${root}${pluralSuffix}`
      }
    </React.Fragment>
  )
}

const markdownTypes = [
  {
    type: "Bold",
    code: "**Bold**"
  },
  {
    type: "Italic",
    code: "*Italic*"
  },
  {
    type: "Strikethrough",
    code: "~~strikethrough~~"
  },
  {
    type: "Heading 1",
    code: "# text"
  },
  {
    type: "Heading 2",
    code: "## text"
  },
  {
    type: "Numbered list",
    code: "1. item"
  },
  {
    type: "Bulleted list",
    code: "* item"
  },
  {
    type: "Quote",
    code: "> text"
  },
  {
    type: "Preformatted",
    code: "```<language> preformatted```",
    link: "https://github.com/conorhastings/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD",
    linkText: "available languages",
  },
  {
    type: "Code",
    code: "`code`"
  },
  {
    type: "Link",
    code: "[Link Text](http://example.com)"
  },
]

const styles = theme => ({
  "@global": {
    ".comment-box": {
      background: "transparent",
    },
    ".comment-box a": {
      color: (theme.palette.type === "light") ? theme.palette.primary.light : theme.palette.grey[400],
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  },
  root: {
    background: "transparent",
  },
  details: {
    display: "block",
  },
  spaced: {
    marginRight: theme.spacing.unit,
  },
  indented: {
    marginLeft: theme.spacing.unit * 6,
  },
  preview: {
    padding: theme.spacing.unit * 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 5,
  },
  comment: {
    marginBottom: theme.spacing.unit * 2,
  },
  commentUser: {
    display: "inline-flex",
    alignItems: "center",
  },
  actions: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
  },
  dot: {
    "&:before": {
      color: theme.palette.text.secondary,
      content: "\".\"",
      display: "inline-block",
      textAlign: "center",
      verticalAlign: "super",
      width: 16,
    },
  },
})

class CommentBox extends React.Component {
  state = {
    comments: this.props.comments || {},
    commentBoxValue: "",
    editCommentObj: null,
    deleteCommentObj: null,
    showButtons: false,
    showHelp: false,
    showPreview: false,
    showConfirmDelete: false,
  }

	componentDidUpdate (prevProps) {
		if (prevProps && prevProps.comments &&
				this.props && this.props.comments &&
        (!_.isEqual(Object.keys(prevProps.comments), Object.keys(this.props.comments)))
			) {
			this.setState({ comments: this.props.comments })
		}
	}

  removeComment = comment => {
    this.setState(state => {
      const newComments = Object.assign({}, state.comments)
      delete newComments[comment.timestamp]

      return {
        comments: newComments,
      }
    })
  }

  handleToggleHelp = () => {
    this.setState(state => {
      return { showHelp: !state.showHelp }
    })
  }

  handleTogglePreview = () => {
    this.setState(state => {
      return { showPreview: !state.showPreview }
    })
  }

  handleKeyDown = event => {
    if (keycode(event) === "enter" && !event.shiftKey) {
      event.target.blur()
      this.handleSaveComment()
    }
  }

  handleInputChange = event => {
    this.setState({
      commentBoxValue: event.target.value,
    })
  }

  handleOpenCommentInput = () => {
    this.setState({
      showButtons: true,
    })
  }

  handleCancelCommentInput = () => {
    this.setState(state => {
      if (state.editCommentObj) {
        state.comments[state.editCommentObj.timestamp] = state.editCommentObj
      }

      return {
        comments: state.comments,
        showButtons: false,
        commentBoxValue: "",
        editCommentObj: null,
        deleteCommentObj: null,
        showConfirmDelete: false,
        showPreview: false,
      }
    })
  }

  handleEditComment = comment => () => {
    this.removeComment(comment)

    this.setState({
      editCommentObj: comment,
      showButtons: true,
      commentBoxValue: comment.markdown,
    })
  }

  handleToggleConfirmDelete = comment => () => {
    this.setState(state => {
      return {
        deleteCommentObj: comment,
        showConfirmDelete: !state.showConfirmDelete
      }
    })
  }

  handleDeleteComment = () => {
    const { timestamp } = this.props
    const { deleteCommentObj } = this.state

    this.props.deleteEventComment(timestamp, deleteCommentObj)

    this.removeComment(deleteCommentObj)

    this.setState({
      deleteCommentObj: null,
      showConfirmDelete: false,
    })
  }

  handleSaveComment = () => {
    const { currentUsername, timestamp } = this.props

    this.setState(state => {
      const commentTimestamp = (state.editCommentObj) ? state.editCommentObj.timestamp : null
      const comment = {
        timestamp: commentTimestamp,
        username: currentUsername,
        markdown: state.commentBoxValue,
        edited: (state.editCommentObj) ? Date.now() : null,
      }

      // save to backend
      this.props.putEventComment(timestamp, comment)

      // put the commment in local stat with a temp timestamp for instant update in the UI
      const localTs = commentTimestamp || Date.now()
      state.comments[localTs] = comment

      return {
        comments: state.comments,
        showButtons: false,
        showPreview: false,
        commentBoxValue: "",
        editCommentObj: null,
      }
    })
  }

  render () {
  	const { classes, currentUsername, currentUserPermissions, expanded } = this.props
  	const { comments, commentBoxValue, showButtons, showHelp, showPreview, showConfirmDelete } = this.state
    const disabled = (commentBoxValue === "" || commentBoxValue === null)
    const authorized = isAuthorized("*", currentUserPermissions, ["PutEventComment", "DeleteEventComment"])

    const reactMarkdownProps = {
      renderers: {
        code: CodeBlock,
      },
      linkTarget: "_blank",
    }

  	return (
      <React.Fragment>
        <ExpansionPanel className={classes.root} elevation={0} defaultExpanded={expanded || !Object.keys(comments).length}>
          <ExpansionPanelSummary>
            <Typography variant="caption" style={{ fontSize: 14, fontWeight: "bold" }}>
              <DynamicDescription
                count={Object.keys(comments).length}
                root="Comment"
                singularSuffix=""
                pluralSuffix="s"
                showZero
              />
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <div className={classes.root}>
              {comments && Object.keys(comments).sort().map(commentTimestamp => {
                const comment = comments[commentTimestamp]

                return (
                  <div key={commentTimestamp} className={classes.comment}>
                    <Paper elevation={0} className="comment-box">
                      <div className={classes.commentUser}>
                        <UserAvatar username={comment.username} spaced />
                        <Typography className={classes.spaced} variant="body1" color="textSecondary">{comment.username}</Typography>
                        <Typography
                          variant="caption"
                          title={Dates.formatFull(parseInt(commentTimestamp, 10))}
                        >
                          {Dates.formatDynamic(parseInt(commentTimestamp, 10))}
                        </Typography>
                      </div>
                      <Typography className={classes.indented} variant="body1" paragraph>
                        <ReactMarkdown {...reactMarkdownProps} source={comment.markdown} />
                        <Typography
                          variant="caption"
                          title={Dates.formatDynamic(parseInt(comment.edited, 10))}
                        >
                          {comment.edited && "(edited)"}
                        </Typography>
                      </Typography>
                    </Paper>
                    {(currentUsername === comment.username) && authorized &&
                      <div className={classes.indented}>
                        <Typography variant="caption">
                          <Anchor style={{ fontSize: 15 }} text="Edit" anchorProps={{ onClick: this.handleEditComment(comment) }} />
                          <span className={classes.dot} />
                          <Anchor text="Delete" anchorProps={{ onClick: this.handleToggleConfirmDelete(comment) }} />
                        </Typography>
                      </div>
                    }
                  </div>
                )
              })}
            </div>

            <div className={classes.root}>
              {!showPreview &&
                <TextField
                  id="outlined-textarea"
                  label="Leave a comment"
                  value={commentBoxValue}
                  placeholder="You can submit plain text or Markdown formatted text in this field"
                  margin="normal"
                  variant="outlined"
                  multiline
                  fullWidth
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  onFocus={this.handleOpenCommentInput}
                />
              }
              {showPreview &&
                <div className={classes.preview}>
                  <ReactMarkdown {...reactMarkdownProps} source={commentBoxValue} />
                </div>
              }

              {showButtons &&
                <div className={classes.actions}>
                  <Button
                    className={classes.spaced}
                    variant="text"
                    size="small"
                    onClick={this.handleCancelCommentInput}
                  >
                    {"Cancel"}
                  </Button>
                  <Button
                    className={classes.spaced}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={disabled}
                    onClick={this.handleSaveComment}
                  >
                    {"Save"}
                  </Button>
                  <div style={{ flexGrow: 1 }} />
                  <Tooltip title="Preview">
                    <IconButton onClick={this.handleTogglePreview}>
                      <CodeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Help">
                    <IconButton onClick={this.handleToggleHelp}>
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              }
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Dialog
          open={showHelp}
          onClose={this.handleToggleHelp}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="title" gutterBottom>Formatting comments with Markdown</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={16}>
              {markdownTypes.map(item => {
                return (
                  <React.Fragment key={item.type}>
                    <Grid item xs={6}>
                      <Typography variant="subheading" color="textSecondary">
                      {item.type}
                      {" "}
                      {item.link && <Anchor text={`(${item.linkText})`} anchorProps={{ href: item.link, target: "_blank", rel: "noopener" }} />}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subheading" color="textSecondary">{item.code}</Typography>
                    </Grid>
                  </React.Fragment>
                )
              })}
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={showConfirmDelete}
          onClose={this.handleToggleConfirmDelete()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="title" gutterBottom>Are you sure?</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Permanently delete this comment.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.spaced}
              variant="text"
              onClick={this.handleCancelCommentInput}
            >
              {"Cancel"}
            </Button>
            <Button
              className={classes.spaced}
              variant="contained"
              color="primary"
              onClick={this.handleDeleteComment}
            >
              {"Delete"}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  	)
  }
}

CommentBox.propTypes = {
  classes: PropTypes.object.isRequired,
}

// function mapStateToProps (state) {
//   return {
//     currentUsername: state.currentUsername,
//     currentUserPermissions: state.currentUserPermissions,
//   }
// }
//
// function mapDispatchToProps (dispatch) {
//   return {
//     putEventComment: (eventId, comment) => (dispatch(putEventComment(eventId, comment))),
//     deleteEventComment: (eventId, comment) => (dispatch(deleteEventComment(eventId, comment))),
//   }
// }

export default withStyles(styles)(CommentBox)
