import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import { SelectableDataGrid } from "@orchestrate-ui/core"
import ResourceLink from "components/ResourceLink"

import { createGroup, deleteGroup } from "backend/groups"

const styles = theme => ({
  description: {
    padding: `${theme.spacing.unit * 3}px 0`,
  },
})

const columns = [
  { id: "name", label: "Name" },
  { id: "description", label: "Description", options: {
		filter: false,
	}},
  { id: "members", label: "Members (#)", options: {
    filterType: "dropdown",
	}},
  { id: "resources", label: "Resources (#)", options: {
    filterType: "dropdown",
	}},
]

class Groups extends React.Component {
  constructor (props) {
    super(props)

    this.createGroup = createGroup.bind(this)
    this.deleteGroup = deleteGroup.bind(this)
  }

  groupPermissions = (group) => {
    return this.props.permissions.filter(x => x.principal === group && x.resource !== "*")
  }

  createTableData = (item) => {
    return {
      id: item.name,
      name: (<ResourceLink resourceKey={item.name} typeOverride="adminGroupDetail" sort={item.name} />),
      description: item.description,
      members: item.users.length,
      resources: this.groupPermissions(item.name).length,
    }
  }

  addGroup = name => {
    const { idToken, reload } = this.props

    this.createGroup(idToken, { name }, reload)
  }

  deleteGroups = groups => {
    const { idToken, reload } = this.props

    groups.forEach(group => {
      // we want to pass the name to deleteGroup()
      this.deleteGroup(idToken, group.id, reload)
    })
  }

  render () {
    const { classes, groupsLoading } = this.props
    const tableData = this.props.groups.map(item => {
      return this.createTableData(item)
    })

    return (
      <React.Fragment>
        <Typography variant="subtitle1" className={classes.description}>
          With user groups, you can organize team members, specify team permissions, and grant access to team environments.
        </Typography>

        <SelectableDataGrid
          loading={groupsLoading}
          tableName={"Groups"}
          columns={columns}
          data={tableData}
          handleAddItem={this.addGroup}
          handleDeleteItems={this.deleteGroups}
        />
      </React.Fragment>
    )
  }
}

Groups.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Groups)
