const config = require("config")

export function loadGroups (idToken) {
  fetch(`${config.authURI}/groups`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
  .then(result => result.json())
  .then(groups => {
    this.setState({
      groups: groups,
      groupsLoading: false,
    })
  })
  .catch(() => {
    this.setState({ groupsLoading: false, error: true })
  })
}


export function addUserToGroup (idToken, groupName, userName, reload) {
  fetch(`${config.authURI}/groups/${groupName}/user/${userName}`, {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${idToken}`
        }
    })
    .then(result => result.json())
    .then(groups => {
      reload()
    })
    .catch(() => {
      this.setState({ groupsLoading: false, error: true });
    });
}

export function removeUserFromGroup (idToken, groupName, userName) {
  fetch(`${config.authURI}/groups/${groupName}/user/${userName}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${idToken}`
        }
    })
    .then(result => result.json())
    .then(groups => {})
    .catch(() => {
      this.setState({ groupsLoading: false, error: true });
    });
}

export function createGroup (idToken, body, reload) {
  fetch(`${config.authURI}/groups`, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${idToken}`
        },
        body: JSON.stringify(body)
    })
    .then(result => result.json())
    .then(groups => {
      reload()
    })
    .catch(() => {
      this.setState({ groupsLoading: false, error: true });
    });
}

export function deleteGroup (idToken, groupName, reload) {
  fetch(`${config.authURI}/groups/${groupName}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${idToken}`
        },
    })
    .then(result => result.json())
    .then(groups => {
      reload()
    })
    .catch(() => {
      this.setState({ groupsLoading: false, error: true });
    });
}
