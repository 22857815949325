import { getUsername, getGroups } from "utils/Screens"
const config = require("config")

export function loadPermissions (idToken) {
  fetch(`${config.authURI}/permissions`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
  .then(result => result.json())
  .then(permissions => {
    const currentUserPermissions = permissions.filter(x => x.principal.toUpperCase() === getUsername(idToken).toUpperCase() || getGroups(idToken).some(y => y.toUpperCase() === x.principal.toUpperCase()))

    this.setState({
      permissions: permissions,
      currentUserPermissions: currentUserPermissions,
      permissionsLoading: false,
    })
  })
  .catch(() => {
    this.setState({ permissionsLoading: false, error: true })
  })
}

export function createPermission (idToken, body, reload) {
  fetch(`${config.authURI}/permissions`, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${idToken}`
        },
        body: JSON.stringify(body)
    })
    .then(result => result.json())
    .then(permissions => {
      reload()
    })
    .catch(() => {
      this.setState({ permissionsLoading: false, error: true });
    });
}

export function updatePermission (idToken, id, body, reload) {
  fetch(`${config.authURI}/permissions/${id}`, {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${idToken}`
        },
        body: JSON.stringify(body)
    })
    .then(result => result.json())
    .then(permissions => {
      reload()
    })
    .catch(() => {
      this.setState({ permissionsLoading: false, error: true });
    });
}

export function deletePermission (idToken, id, reload) {
  fetch(`${config.authURI}/permissions/${id}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${idToken}`
        },
    })
    .then(result => result.json())
    .then(permissions => {
      reload()
    })
    .catch(() => {
      this.setState({ permissionsLoading: false, error: true });
    });
}
