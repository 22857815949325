import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "@material-ui/core/styles"
import SyntaxHighlighter from "react-syntax-highlighter"
import { github, dark } from "react-syntax-highlighter/dist/cjs/styles/hljs"

const CodeBlock = props => {
  const { theme, language, value } = props
  const style = (theme.palette.type === "dark") ? dark : github

  return (
    <SyntaxHighlighter
      language={language}
      style={style}
    >
      {value}
    </SyntaxHighlighter>
  )
}

CodeBlock.propTypes = {
  value: PropTypes.string.isRequired,
  language: PropTypes.string,
}

CodeBlock.defaultProps = {
  value: "",
  language: null,
}

export default withTheme()(CodeBlock)
