import React from "react"
import { useEffect } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import Chip from "@material-ui/core/Chip"

import { AutoSelect } from "@orchestrate-ui/core"
import { SelectableDataGrid } from "@orchestrate-ui/core"

import ResourceLink from "components/ResourceLink"

import { loadEnvironments } from "backend/environments"
import { createPermission, updatePermission, deletePermission } from "backend/permissions"

const styles = theme => ({
  description: {
    padding: `${theme.spacing.unit * 3}px 0`,
  },
  formControl: {
    margin: [[theme.spacing.unit * 2, 0]],
    width: 500,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit * 0.25,
  },
  menuItem: {
    height: "auto",
    maxWidth: 500,
    whiteSpace: "normal",
  },
})

const columns = [
  { id: "name", label: "Name", options: {
		filter: false,
	}},
	{ id: "principal", label: "Principal", options: {
		display: false,
	}},
  { id: "actions", label: "Actions", options: {
    filter: false,
    customBodyRender (value, tableMeta, updateValue) {
      return (Array.isArray(value)) ? value.join(", ") : value
    }
  }},
  { id: "resourceLink", label: "Resource", options: {
		filter: false,
	}},
	{ id: "resource", label: "Resource", options: {
		display: false,
		filterType: "multiselect",
	}},
]

class Permissions extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      localResources: [],
      localPermissions: [],
      principal: "",
      resource: "",
      environments: [],
      initialLoadDone: false,
    }

    this.loadEnvironments = loadEnvironments.bind(this)
    this.createPermission = createPermission.bind(this)
    this.updatePermission = updatePermission.bind(this)
    this.deletePermission = deletePermission.bind(this)
  }

  createTableData = (item) => {
    return {
      id: item.id,
      principal: item.principal,
      name: (<ResourceLink resourceKey={item.principal} typeOverride={this.userOrGroup(item.principal)} sort={item.principal} />),
      actions: item.actions,
      resourceLink: (<ResourceLink resourceKey={item.resource} sort={item.resource} />),
      resource: item.resource,
    }
  }

  entityPermissions = (entity) => {
    const { permissions } = this.props
    return permissions.filter(x => x.resource === entity)
  }

  handleChange = (items, event) => {
    this.setState({ [items]: event.target.value })
  }

  handleSelectChange = item => {
    this.setState({ principal: item.value })
  }

  handleDeleteMultiValue = (items, value) => {
    const newArray = [].concat(this.state[items])
    const deleteIndex = newArray.indexOf(value)
    newArray.splice(deleteIndex, 1)

    this.setState({ [items]: newArray })
  }

  handleSave = () => {
    const { idToken, reload } = this.props
    const { localResources, localPermissions, principal } = this.state

    localResources.forEach(resource => {
      const existingPermissions = this.entityPermissions(resource)
      const found = existingPermissions.find(x => x.principal === principal)

      if (found) {
        this.updatePermission(idToken, found.id, {
          principal: principal,
          actions: localPermissions,
          resource: resource,
        }, reload)
      }
      else {
        this.createPermission(idToken, {
          principal: principal,
          actions: localPermissions,
          resource: resource,
        }, reload)
      }
    })

    this.setState({ principal: "" })
    this.setState({ localResources: [] })
    this.setState({ localPermissions: [] })
  }

  deletePermissions = permissions => {
    const { idToken, reload } = this.props

    permissions.forEach(permission => {
      // we want to pass the name to deletePermission()
      this.deletePermission(idToken, permission.id, reload)
    })
  }

  userOrGroup = principal => {
    if (this.props.users.find(x => x.username === principal)) {
      return "adminUserDetail"
    }
    if (this.props.groups.find(x => x.name === principal)) {
      return "adminGroupDetail"
    }

    return undefined
  }

  AddDialog = () => {
    useEffect(() => {
      const { idToken } = this.props

      if (!this.state.loadingEnvironments && !this.state.initialLoadDone) {
        this.setState({ initialLoadDone: true })
        this.loadEnvironments(idToken, "?filter=environment_key")
      }
    })

    const { classes, users, groups } = this.props
    const { localResources, localPermissions, environments } = this.state
    const suggestions = users.map(x => x.username).concat(groups.map(x => x.name))

    return (
      <React.Fragment>
        <DialogTitle>{"Add a permission"}</DialogTitle>
        <DialogContent>
          <form autoComplete="off">
            <FormControl required style={{ minWidth: 200 }}>
              <AutoSelect
                multiple
                enumerations={suggestions}
                placeholder={"User or Group name"}
                maxMenuHeight={75}
                onChange={this.handleSelectChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-chip-resources">Resource(s)</InputLabel>
              <Select
                multiple
                value={localResources}
                onChange={e => this.handleChange("localResources", e)}
                input={<Input id="select-multiple-chip-resources" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip key={value} label={value} className={classes.chip} onDelete={() => this.handleDeleteMultiValue("localResources", value)} />
                    ))}
                  </div>
                )}
              >
                {environments.map(resource => (
                  <MenuItem className={classes.menuItem} key={resource.environment_key} value={resource.environment_key}>
                    <div>
                      <Typography style={{ marginRight: 5 }}>{resource.environment_key}</Typography>
                      <Typography variant="caption">{resource.data.environment.description}</Typography>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-chip-permissions">Permission(s)</InputLabel>
              <Select
                multiple
                value={localPermissions}
                onChange={e => this.handleChange("localPermissions", e)}
                input={<Input id="select-multiple-chip-permissions" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip key={value} label={value} className={classes.chip} onDelete={() => this.handleDeleteMultiValue("localPermissions", value)} />
                    ))}
                  </div>
                )}
              >
                {window.Claims.map(claim => (
                  <MenuItem key={claim.name} value={claim.name}>
                    <Typography style={{ marginRight: 5 }}>{claim.name}</Typography>
                    <Typography variant="caption">{claim.description}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
      </React.Fragment>
    )
  }

  render () {
    const { classes, permissionsLoading } = this.props
    const tableData = this.props.permissions.map(item => {
      return this.createTableData(item)
    })

    return (
      <React.Fragment>
        <Typography variant="subtitle1" className={classes.description}>
          This table shows all of the permissions configured for your team. You can create, update, and delete permissions at a global level here.
        </Typography>

        <SelectableDataGrid
          loading={permissionsLoading}
          tableName={"Permissions"}
          columns={columns}
          data={tableData}
          DialogComponent={this.AddDialog}
          handleAddItem={this.handleSave}
          handleDeleteItems={this.deletePermissions}
        />
      </React.Fragment>
    )
  }
}

Permissions.propTypes = {
  classes: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
}

Permissions.defaultProps = {
  permissions: [{ id: 1, principal: "loading", actions: [], resource: "loading" }],
}

export default withStyles(styles)(Permissions)
