/*eslint camelcase: "off"*/

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"

import DiffView from "diff-view-react"

const styles = theme => ({
  diffPanel: {
    width: "100%",
    border: "none",
    color: theme.palette.text.primary,
    overflow: "hidden",
  },
  "@global": {
    ".diff-event .ins": {
      background: "rgba(0, 250, 154, 0.4)",
      color: (theme.palette.type === "light") ? "rgba(0,0,0,0.7)" : "rgba(255,255,255,0.7)",
    },
    ".diff-event .del": {
      background: "rgba(205, 92, 92, 0.4)",
      color: (theme.palette.type === "light") ? "rgba(0,0,0,0.7)" : "rgba(255,255,255,0.7)",
    },
    ".diff-event tr > :nth-child(-n+2)": {
      color: theme.palette.text.secondary,
      fontFamily: "monospace",
    }
  },
})

const DiffEvent = (props) => {
	const { classes, config_diff } = props
  const oldItem = (config_diff) ? config_diff.old : null
  const newItem = (config_diff) ? config_diff.new : null

	return (
    <ExpansionPanelDetails className="diff-event">
      {config_diff &&
        <DiffView className={classes.diffPanel} baseText={JSON.stringify(oldItem, null, 4)} newText={JSON.stringify(newItem, null, 4)} />
      }
      {!config_diff &&
        <Typography variant="caption"><i>{"no diff found"}</i></Typography>
      }
    </ExpansionPanelDetails>
	)
}

DiffEvent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DiffEvent)
