/* eslint react/no-did-update-set-state: off */

import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Tooltip from "@material-ui/core/Tooltip"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined"

import _ from "lodash"

import { Anchor } from "@orchestrate-ui/core"
import { UserAvatar } from "@orchestrate-ui/core"

// import { likeEvent, unlikeEvent } from "state/actions"

const styles = theme => ({
	root: {
		display: "inline-flex",
		alignItems: "center",
		marginTop: 5,
	},
	default: {
		height: 30,
		width: 30,
		marginRight: 5,
		padding: 0,
		color: theme.palette.grey[400],
		"&:hover": {
			color: theme.palette.grey[500],
		},
	},
	active: {
		color: theme.palette.grey[500],
	},
	icon: {
		fontSize: 15,
	},
})

class LikeToggle extends Component {
	constructor (props) {
		super(props)

		this.state = {
			liked: this.isLiked(),
			open: false,
		}
	}

	componentDidUpdate (prevProps) {
		if (
				(prevProps.likes && !this.props.likes) ||
				(this.props.likes && !prevProps.likes) ||
        (prevProps.likes && this.props.likes && !_.isEqual(prevProps.likes.values, prevProps.likes.values))
			) {
			this.setState({ liked: this.isLiked() })
		}
	}

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

	handleLike = () => {
		const { timestamp, currentUsername } = this.props
		this.setState({ liked: true })

		this.props.likeEvent(timestamp, currentUsername)
	}

	handleUnlike = () => {
		const { timestamp, currentUsername } = this.props
		this.setState({ liked: false })

		this.props.unlikeEvent(timestamp, currentUsername)
	}

	isLiked = () => {
		const { currentUsername, likes } = this.props

		if (likes && likes.values) {
			const array = likes.values

			return (array.includes(currentUsername))
		}

		return false
	}

	getDescription = () => {
		const { currentUsername, likes } = this.props
		const { liked } = this.state

		if (likes && likes.values) {
			const array = likes.values
			const displayName = (liked) ? "You" : array[0]

			if (array.length <= 1) {
				if (liked) {
					return `${displayName} like this`
				}
				else if (currentUsername === displayName) {
					return ""
				}

				return `${displayName} likes this`
			}

			if (array.length === 2) {
				return (<React.Fragment>{`${displayName} and `}<Anchor text={`${array.length - 1} other`} anchorProps={{ onClick: this.handleClickOpen }} />{" like this"}</React.Fragment>)
			}

			if (array.length > 2) {
				return (<React.Fragment>{`${displayName} and `}<Anchor text={`${array.length - 1} others`} anchorProps={{ onClick: this.handleClickOpen }} />{" like this"}</React.Fragment>)
			}
		}
		else if (liked) {
			return "You like this"
		}
		else {
			return ""
		}

		return ""
	}

	render () {
		const { classes, likes } = this.props
		const { liked } = this.state
		const description = this.getDescription()

		const buttonClick = (liked) ? this.handleUnlike : this.handleLike
		const buttonIcon = (liked) ? <ThumbUpIcon className={classes.icon} /> : <ThumbUpOutlinedIcon className={classes.icon} />
		const tooltip = (liked) ? "Unlike" : "Like"

		return (
			<React.Fragment>
				<div className={classes.root}>
		      <Tooltip title={tooltip}>
						<IconButton
							className={classNames(classes.default, this.state.liked && classes.active)}
							onClick={buttonClick}
						>
							{buttonIcon}
						</IconButton>
					</Tooltip>

					<Typography variant="caption">{description}</Typography>
				</div>


				{likes &&
					<Dialog
						open={this.state.open}
						onClose={this.handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{`Likes (${likes.values.length})`}</DialogTitle>
						<DialogContent>
							<List dense>
								{likes.values.map(item => {
									return (
										<ListItem key={item}>
											<ListItemAvatar>
												<UserAvatar username={item} />
											</ListItemAvatar>
											<ListItemText primary={item} />
										</ListItem>
									)
								})}
							</List>
						</DialogContent>
					</Dialog>
				}
			</React.Fragment>
		)
	}
}

LikeToggle.propTypes = {
  classes: PropTypes.object.isRequired,
}

// function mapStateToProps (state) {
// 	return {
// 		screen: state.screen,
// 		currentUsername: state.currentUsername,
// 	}
// }
//
// function mapDispatchToProps (dispatch) {
// 	return {
// 		likeEvent: (timestamp, username) => dispatch(likeEvent(timestamp, username)),
// 		unlikeEvent: (timestamp, username) => dispatch(unlikeEvent(timestamp, username)),
// 	}
// }

export default withStyles(styles)(LikeToggle)
