import "react-app-polyfill/ie11"
import React from "react"
import ReactDOM from "react-dom"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { unregister } from "utils/registerServiceWorker"
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk"

import { getLDUser } from "utils/Screens"

import AdminPage from "pages/AdminPage"
import EventDetails from "pages/EventDetails"

const components = [
  {
    name: "AdminPage",
    Component: AdminPage,
  },
  {
    name: "EventDetails",
    Component: EventDetails,
  },
]

components.forEach(c => {
  const { name, Component } = c

  window[`render${name}`] = async (containerId, props) => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env['REACT_APP_LAUNCHDARKLY_ENV_ID'],
      user: getLDUser(),
    });

    ReactDOM.render(
      <LDProvider>
        <MuiThemeProvider theme={props && props.theme}>
          <Component {...props} />
        </MuiThemeProvider>
      </LDProvider>,
      document.getElementById(containerId),
    )
    unregister()
  }

  window[`unmount${name}`] = containerId => {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId))
  }
})
