/*eslint camelcase: "off"*/

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"

import { Anchor } from "@orchestrate-ui/core"

const styles = theme => ({
  inlineCenter: {
    alignItems: "baseline",
    marginBottom: theme.spacing.unit * 2,
  },
  indented: {
    marginLeft: theme.spacing.unit,
  },
})

const DeployEvent = props => {
  const { classes } = props

  return (
    <ExpansionPanelDetails className={classes.indented}>
      {(props.build_url || props.result) &&
        <div>
          {props.result &&
            <div className={classes.inlineCenter}>
              <Typography variant="caption">{"Result"}</Typography>
              <Typography>{props.result}</Typography>
            </div>
          }
          {props.build_url &&
            <div className={classes.inlineCenter}>
              <Typography variant="caption">{"Pipeline"}</Typography>
              <Anchor text={props.build_url} anchorProps={{ href: props.build_url, target: "_blank" }} />
            </div>
          }
        </div>
      }
      {!(props.build_url || props.result) && <Typography variant="caption"><i>{"no deployment metadata reported"}</i></Typography>}
    </ExpansionPanelDetails>
  )
}

DeployEvent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DeployEvent)
