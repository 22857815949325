import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { createBrowserHistory } from "history"
import { Link, Router, Switch, Route, Redirect } from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import PersonIcon from "@material-ui/icons/Person"
import AccountGroupIcon from "mdi-material-ui/AccountGroup"
import AccountKeyIcon from "mdi-material-ui/AccountKey"
// import PublicIcon from "@material-ui/icons/Public"
// import SlackIcon from "mdi-material-ui/Slack"

import { Placeholder } from "@orchestrate-ui/core"
import { SecondarySideBar } from "@orchestrate-ui/core"
import { SecondarySideBarHeader } from "@orchestrate-ui/core"
import { SecondarySideBarTitle } from "@orchestrate-ui/core"
import { SecondarySideBarItem } from "@orchestrate-ui/core"
import { SecondarySideBarDivider } from "@orchestrate-ui/core"

import Users from "./Users"
import UserDetail from "./UserDetail"
import Groups from "./Groups"
import GroupDetail from "./GroupDetail"
import Permissions from "./Permissions"
// import Slack from "pages/Slack"
// import Jenkins from "pages/Jenkins"

import { loadUsers } from "backend/users"
import { loadGroups } from "backend/groups"
import { loadPermissions } from "backend/permissions"

const defaultHistory = createBrowserHistory()

const styles = theme => ({
  root: {
    display: "flex",
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing.unit,
    width: "calc(100% - 160px)",
  },
})

class AdminPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      groups: [],
      users: [],
      permissions: [],
      usersLoading: true,
      groupsLoading: true,
      permissionsLoading: true,
      error: false,
    }

    this.loadUsers = loadUsers.bind(this)
    this.loadGroups = loadGroups.bind(this)
    this.loadPermissions = loadPermissions.bind(this)
  }

  componentDidMount() {
    this.reload()
  }

  reload = () => {
    const { idToken } = this.props

    this.setState({ error: false })

    this.loadUsers(idToken)
    this.loadGroups(idToken)
    this.loadPermissions(idToken)
  }

  render() {
    const { classes, secondarySideBarOpen, toggleSecondarySideBar, match, history, location } = this.props

    const links1 = [
      {
        label: "Users",
        icon: (<PersonIcon />),
        link: `/admin/users`,
      },
      {
        label: "Groups",
        icon: (<AccountGroupIcon />),
        link: `/admin/groups`,
      },
    ]

    const links2 = [
      {
        label: "Permissions",
        icon: (<AccountKeyIcon />),
        link: `/admin/permissions`,
      },
    ]

    const { error } = this.state

    return (
      <Router history={history || defaultHistory}>
        <React.Fragment>
          <SecondarySideBar open={secondarySideBarOpen} toggle={toggleSecondarySideBar}>
            <SecondarySideBarHeader>
              <Typography variant="h6">{"Access Management"}</Typography>
            </SecondarySideBarHeader>

            <SecondarySideBarTitle>{"Users & Groups"}</SecondarySideBarTitle>
            {links1.map(item => (
              <Link key={item.link} to={item.link} style={{ color: "inherit", textDecoration: "none" }}>
                <SecondarySideBarItem key={item.link} location={location} {...item} />
              </Link>
            ))}

            <SecondarySideBarDivider />

            <SecondarySideBarTitle>{"Access Control"}</SecondarySideBarTitle>
            {links2.map(item => (
              <Link key={item.link} to={item.link} style={{ color: "inherit", textDecoration: "none" }}>
                <SecondarySideBarItem key={item.link} location={location} {...item} />
              </Link>
            ))}
          </SecondarySideBar>

          {error &&
            <Placeholder size="small">
              {"Sorry, but the this page is unavailable right now, "}
              <Button variant="outlined" size="small" onClick={this.reload}>Reload</Button>
            </Placeholder>
          }

          {!error &&
            <div className={classes.root}>
              <main className={classes.container}>
                <Switch>
                  <Route
                    exact
                    path="/admin/"
                    render={() => (
                      <Redirect to={`${match.url}/users`} />
                    )}
                  />
                  <Route
                    exact
                    path="/admin/users/:key"
                    render={props => <UserDetail idToken={this.props.idToken} reload={this.reload} {...props} {...this.state} />}
                  />
                  <Route
                    exact
                    path="/admin/users"
                    render={() => <Users {...this.state} />}
                  />
                  <Route
                    exact
                    path="/admin/groups/:key"
                    render={props => <GroupDetail idToken={this.props.idToken} reload={this.reload} {...props} {...this.state} />}
                  />
                  <Route
                    exact
                    path="/admin/groups"
                    render={props => <Groups idToken={this.props.idToken} reload={this.reload} {...props} {...this.state} />}
                  />
                  <Route
                    exact
                    path="/admin/permissions"
                    render={props => <Permissions idToken={this.props.idToken} reload={this.reload} {...props} {...this.state} />}
                  />
                </Switch>
              </main>
            </div>
          }
        </React.Fragment>
      </Router>
    )
  }
}

AdminPage.propTypes = {
  idToken: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  secondarySideBarOpen: PropTypes.bool.isRequired,
  toggleSecondarySideBar: PropTypes.func.isRequired,
}

AdminPage.defaultProps = {
  match: {},
  location: {},
  secondarySideBarOpen: true,
  toggleSecondarySideBar: () => {},
}

export default withStyles(styles)(AdminPage)
