import React from "react"
import compose from "recompose/compose"
import PropTypes from "prop-types"
import { withTheme, withStyles } from "@material-ui/core/styles"
import { createBrowserHistory } from "history"
import { Router } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Redirect } from "react-router-dom"
import { Placeholder } from "@orchestrate-ui/core"
import { UserAvatar } from "@orchestrate-ui/core"
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import CommentBox from "components/CommentBox"
import LikeToggle from "components/LikeToggle"
import ResourceLink from "components/ResourceLink"
import Status from "components/Status"

import { isAuthorized } from "utils/Screens"
import { timestampToId, getEventConfig } from "utils/Events"

import Dates from "utils/Dates"

import { loadEvents} from "backend/events"
import { loadPermissions} from "backend/permissions"

const defaultHistory = createBrowserHistory()

const styles = theme => ({
	"@keyframes blink": {
		"50%": { opacity: 0.5 }
	},
  root: {
    margin: "auto",
  },
	flexEnd: {
		display: "inline-flex",
		alignItems: "flex-end",
	},
	flexCenter: {
		display: "inline-flex",
		alignItems: "center",
	},
  spaced: {
    paddingRight: theme.spacing.unit * 2,
  },
  summary: {
    padding: `${theme.spacing.unit * 5}px 0`,
  },
  indented: {
    paddingLeft: theme.spacing.unit * 3,
  },
  icon: {
    border: "2px solid #000",
    borderRadius: "50%",
  },
  sideItem: {
    paddingBottom: theme.spacing.unit * 2,
  },
	placeholder: {
		width: "80%",
    minWidth: 75,
		height: 10,
		background: theme.palette.action.hover,
		borderRadius: 4,
		animation: "blink 4s ease-in-out infinite",
	},
  placeholderCircle: {
    height: 30,
    width: 40,
    marginRight: 20,
    borderRadius: "50%",
    background: theme.palette.action.hover,
  },
})

class EventDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      events: [],
      permissions: [],
      currentUserPermissions: [],
      eventsLoading: true,
      permissionsLoading: true,
      error: false,
    }

    this.loadEvents = loadEvents.bind(this)
    this.loadPermissions = loadPermissions.bind(this)
  }

  componentDidMount() {
    this.reload()
  }

  reload = () => {
    const { idToken } = this.props

    this.setState({ error: false })

    this.loadEvents(idToken)
    this.loadPermissions(idToken)
  }

  render () {
    const { orcEventLoadingScreen } = this.props.flags
    const { classes, theme, match, history, utcTime, testKey } = this.props
    const { events, currentUserPermissions, permissionsLoading, eventsLoading } = this.state

    const isLoading = permissionsLoading || eventsLoading

    const key = (match) ? match.params.key : testKey
    const detailsEvent = (events) ? events.find(x => (x.timestamp === key || timestampToId(x.timestamp) === key || timestampToId(x.timestamp, true) === key)) : null
    const { EventComponent, EventIcon, color, name, description } = (detailsEvent) ? getEventConfig(detailsEvent.event) : {}
    const authForComments = isAuthorized("*", currentUserPermissions, ["PutEventComment", "DeleteEventComment"])
    const authForLikes = isAuthorized("*", currentUserPermissions, ["LikeEvent", "UnlikeEvent"])

		const LoadingBar = ({ variant = "short", width }) => (
      <>
        {variant === "short" &&
          <div className={classes.placeholder} style={{ height: 20, width: width }}/>
        }
        {variant === "medium" &&
          <div className={classes.placeholder} style={{ height: 50, width: width }}/>
        }
        {variant === "tall" &&
          <div className={classes.placeholder} style={{ height: 125, width: width }}/>
        }
      </>
    )

    const LoadingCircle = () => (
      <div className={classes.placeholderCircle} />
    )

    const sideInfo = (detailsEvent) ? [
      {
        label: "Actor",
        value: (
          <div className={classes.flexCenter}>
            <UserAvatar size={30} username={detailsEvent.event.username} spaced />
            <Typography>{detailsEvent.event.username}</Typography>
          </div>
        ),
      },
      {
        label: "Result",
        value: (detailsEvent.result)
          ? (<Status variant="badge" badgeContent={detailsEvent.result} />)
          : (<Typography variant="caption">n/a</Typography>),
      },
      {
        label: "Created",
        value: (
          <Typography variant="caption" title={Dates.formatFull(detailsEvent.timestamp, utcTime)}>
            {Dates.formatDynamic(detailsEvent.timestamp, utcTime)}
          </Typography>
        ),
      },
      {
        label: "Updated",
        value: (
          <Typography variant="caption" title={(detailsEvent.last_updated) ? Dates.formatFull(detailsEvent.last_updated, utcTime) : "never"}>
            {(detailsEvent.last_updated)
              ? Dates.formatDynamic(detailsEvent.last_updated, utcTime)
              : "never"
            }
          </Typography>
        ),
      },
    ]
    : [
      {
        label: "Actor",
        value: (
          <div className={classes.flexCenter}>
            <LoadingCircle />
            <LoadingBar />
          </div>
        ),
      },
      {
        label: "Result",
        value: (<LoadingBar />),
      },
      {
        label: "Created",
        value: (<LoadingBar />),
      },
      {
        label: "Updated",
        value: (<LoadingBar />),
      },
    ]

    const LoadingDetailsGrid = () => {
      return (
        <Grid item sm={12} md={10} xl={9} className={classes.root}>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={9}>
                <div className={classes.flexCenter}>
                  <LoadingCircle />
                  <LoadingBar />
                </div>
              <Typography variant="body1" className={classes.summary}>
                <div className={classes.flexCenter}>
                  <LoadingCircle/>
                  <LoadingBar />
                </div>
              </Typography>

              <Typography variant="subtitle1">Activity</Typography>
              <div className={classes.flexCenter} style={{ marginBottom: 10 }}>
                <LoadingCircle />
                <LoadingBar />
              </div>
              <LoadingBar variant="medium" />

              <Typography variant="subtitle1" style={{ marginTop: 20 }}>Details</Typography>
              <LoadingBar variant="tall" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container alignItems="center">
                {sideInfo.map(item => {
                  return (
                    <Grid item xs={12} key={item.label} className={classes.sideItem}>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="caption">{item.label}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>{item.value}</Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    const Loading = () => {
      return (
        orcEventLoadingScreen
        ? <LoadingDetailsGrid />
        : <Placeholder>Loading</Placeholder>
      )
    }

    return (
      <Router history={history || defaultHistory}>
        {!events && isLoading &&
          <Loading />
        }

        {events && isLoading && !detailsEvent &&
          <Loading />
        }

        {events && !isLoading && !detailsEvent &&
          <Redirect data-test-id="redirect-to-404" to="/404" />
        }

        {events && detailsEvent &&
          <Grid item sm={12} md={10} xl={9} className={classes.root}>
            <Grid container spacing={32}>
              <Grid item xs={12} sm={9}>
                <div className={classes.flexEnd}>
                  <div className={classes.spaced}>
                    <EventIcon className={classes.icon} style={{ color: theme.palette.status[color], borderColor: theme.palette.status[color] }} />
                  </div>
                  <Typography variant="h6" className={classes.spaced}>{name}</Typography>
                  {authForLikes && <LikeToggle {...detailsEvent} />}
                </div>
                <Typography variant="body1" className={classes.summary}>
                  <div className={classes.flexCenter}>
                    <UserAvatar size={30} username={detailsEvent.event.username} spaced />
                    <span><b>{detailsEvent.event.username}</b> {description} <ResourceLink resourceKey={detailsEvent.event.resource} /></span>
                  </div>
                </Typography>

                <Typography variant="subtitle1">Activity</Typography>
                {authForComments && <CommentBox {...detailsEvent} expanded />}

                <Typography variant="subtitle1">Details</Typography>
                {EventComponent && <EventComponent {...detailsEvent} />}
                {!EventComponent &&
                  <Typography className={classes.indented} variant="caption"><i>{"no details reported for this event..."}</i></Typography>
                }
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid container alignItems="center">
                  {sideInfo.map(item => {
                    return (
                      <Grid item xs={12} key={item.label} className={classes.sideItem}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="caption">{item.label}</Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>{item.value}</Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </Router>
    )
  }
}

EventDetails.propTypes = {
  classes: PropTypes.object.isRequired,
}

EventDetails.defaultProps = {
  currentUserPermissions: [],
}

export default compose(
  withTheme(),
  withStyles(styles),
  withLDConsumer()
)(EventDetails)
