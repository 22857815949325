/* eslint camelcase: off */

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import Chip from "@material-ui/core/Chip"
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined"
import NotInterestedIcon from "@material-ui/icons/NotInterested"

import { SelectableDataGrid } from "@orchestrate-ui/core"
import ResourceLink from "components/ResourceLink"
import Dates from "utils/Dates"

import _ from "lodash"
import { createPermission, updatePermission } from "backend/permissions"

const styles = theme => ({
  content: {
    padding: theme.spacing.unit * 3,
  },
  spaced: {
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflowWrap: "break-word",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  field: {
    margin: theme.spacing.unit,
  },
  global: {
		display: "flex",
		alignItems: "center",
    marginLeft: theme.spacing.unit * 3,
  },
  check: {
    paddingRight: theme.spacing.unit,
    fontSize: "2rem",
    color: theme.palette.status.success,
  },
  description: {
    paddingLeft: theme.spacing.unit,
  },
  none: {
    paddingRight: theme.spacing.unit,
    fontSize: "2rem",
    color: theme.palette.text.secondary,
  },
  row: {
    padding: theme.spacing.unit * 0.75,
  },
  name: {
    paddingLeft: theme.spacing.unit * 2,
    fontWeight: "bold",
  },
  value: {
    marginTop: theme.spacing.unit * 3,
  },
  spacer: {
    height: theme.spacing.unit * 2,
  },
  header: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  actions: {
    alignItems: "flex-end",
  },
  formControl: {
    margin: theme.spacing.unit,
    width: 500,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit * 0.25,
  },
})

const columns = [
  { id: "resourceLink", label: "Resource", options: {
    filter: false,
  }},
  { id: "resource", label: "Resource", options: {
    display: false,
    filterType: "multiselect",
  }},
  { id: "permissions", label: "Permissions", options: {
    filter: false,
    customBodyRender (value, tableMeta, updateValue) {
      return (Array.isArray(value)) ? value.join(", ") : value
    }
  }},
]

class UserDetail extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      globalPermissionsId: null,
      globalPermissions: [],
    }

    this.createPermission = createPermission.bind(this)
    this.updatePermission = updatePermission.bind(this)
  }

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(prevProps.permissions, this.props.permissions)) {
      this.userGlobalPermissions()
    }
  }

  UNSAFE_componentWillReceiveProps = () => {
    this.userGlobalPermissions()
  }

  userGroups = user => {
    return this.props.groups.filter(x => {
      return Boolean(x.users.find(y => y.username === user))
    })
  }

  userGlobalPermissions = () => {
    const { match, permissions } = this.props
    const p = permissions.filter(x => x.principal === match.params.key && x.resource === "*")

    const ret = (p[0]) ? p[0] : { id: null, actions: [] }

    this.setState({
      globalPermissionsId: ret.id,
      globalPermissions: ret.actions
    })
  }

  userPermissions = () => {
    const { match, permissions } = this.props
    return permissions.filter(x => x.principal === match.params.key && x.resource !== "*")
  }

  findUser = name => {
    const { users } = this.props

    const user = users.find(x => x.username === name)
    if (user) {
      user.groups = this.userGroups(name)
    }

    return user || {}
  }

  formatDetailKey = key => {
    return key
      .split("_")
      .map(word => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ")
  }

  formatDetailValue = (key, value) => {
    if (key.endsWith("_at")) {
      return Dates.formatFull(value)
    }

    return value.toString()
  }

  handleChange = event => {
    this.setState({ globalPermissions: event.target.value })
  }

  handleOpenDialog = () => {
    this.setState({ open: true })
  }

  handleCloseDialog = () => {
    this.setState({ open: false })
  }

  handleDeletePermission = value => () => {
    const newArray = [].concat(this.state.globalPermissions)
    const deleteIndex = newArray.indexOf(value)
    newArray.splice(deleteIndex, 1)

    this.setState({ globalPermissions: newArray })
  }

  handleSave = () => {
    const { idToken, match, reload } = this.props
    const { globalPermissionsId, globalPermissions } = this.state

    if (globalPermissionsId) {
      this.updatePermission(idToken, globalPermissionsId, {
        principal: match.params.key,
        actions: globalPermissions,
        resource: "*",
      }, reload)
    }
    else {
      this.createPermission(idToken, {
        principal: match.params.key,
        actions: globalPermissions,
        resource: "*",
      }, reload)
    }

    this.handleCloseDialog()
  }

  render () {
    const { classes, match } = this.props
    const { globalPermissions, open } = this.state
    const data = this.findUser(match.params.key)

    const tableData = this.userPermissions().map(item => {
      return {
        id: item.id,
        resource: item.resource,
        resourceLink: (<ResourceLink resourceKey={item.resource} sort={item.resource} />),
        permissions: item.actions,
      }
    })

    return (
      <React.Fragment>
        <div className={classes.header}>
          <Typography className={classes.title} variant="h5" gutterBottom>{data.username}</Typography>
          <div className={classes.actions}>
            <Button variant="outlined" onClick={this.handleOpenDialog}>Edit</Button>
          </div>
        </div>

        <div className={classes.spacer} />

        <Typography gutterBottom>
          This user has the following Global Permissions
        </Typography>

        {globalPermissions && globalPermissions.map(p => {
          return (
            <Typography key={p} className={classes.global}>
              <CheckCircleOutlinedIcon className={classes.check} />
              {p}
              <Typography variant="caption" className={classes.description}>{window.Claims.find(x => x.name === p).description}</Typography>
            </Typography>
          )
        })}

        {globalPermissions.length === 0 &&
          <Typography className={classes.global}>
            <NotInterestedIcon className={classes.none} />
            None
          </Typography>
        }

        <Paper className={classes.spaced}>
          <Typography variant="h6">Details</Typography>
          <Grid container>
            {Object.keys(data).map(key => {
              return (
                <React.Fragment key={key}>
                  <Grid item xs={12} md={3} className={classes.row}>
                    <Typography align="left" className={classes.name}>{this.formatDetailKey(key)}</Typography>
                  </Grid>

                  <Grid item xs={12} md={8} className={classes.row}>
                    <Typography>
                      {typeof data[key] === "object" &&
                        (this.formatDetailValue(key, data[key].map(object => object.name).join(", ")))
                      }

                      {typeof data[key] !== "object" &&
                        (this.formatDetailValue(key, data[key]))
                      }
                    </Typography>
                  </Grid>
                  {/* These grid items are just filler for spacing */ }
                  <Grid item xs={12} md={1} />
                </React.Fragment>
              )
            })}
          </Grid>
        </Paper>

        <SelectableDataGrid
          tableName={"Resource Permissions"}
          tableSubheader={"This shows a list of currently active resource permissions. To change these permissions, you must edit the resource config directly."}
          columns={columns}
          data={tableData}
          staticDefaultTableRows={5}
          readonly
        />

        <Dialog
          open={open}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle>
            <Typography variant="h6">Edit user: {data.username}</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Global Permissions</InputLabel>
                <Select
                  multiple
                  value={globalPermissions}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} onDelete={this.handleDeletePermission(value)} />
                      ))}
                    </div>
                  )}
                >
                  {window.Claims && window.Claims.map(claim => (
                    <MenuItem key={claim.name} value={claim.name}>
                      <Typography style={{ marginRight: 5 }}>{claim.name}</Typography>
                      <Typography variant="caption">{claim.description}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} autoFocus>
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

UserDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UserDetail)
