import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import LastPageIcon from "@material-ui/icons/LastPage"
import NoteAddIcon from "@material-ui/icons/NoteAdd"
import CreateIcon from "@material-ui/icons/Create"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import PowerOffIcon from "@material-ui/icons/PowerOff"
import PowerIcon from "@material-ui/icons/Power"
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd"
import CoffeeIcon from "mdi-material-ui/Coffee"

import DiffEvent from "components/DiffEvent"
import DeployEvent from "components/DeployEvent"
// import ConsoleEvent from "components/ConsoleEvent"

const eventConfigs = [
  {
    action: "default",
    icon: HelpOutlineIcon,
    color: "default",
    name: "Unknown",
    description: "",
    component: null,
  },
  {
    action: "StartDeployment",
    icon: LastPageIcon,
    color: "pending",
    name: "Start Deployment",
    description: "started a deployment to",
    component: DeployEvent,
  },
  {
    action: "StartDatapakDeployment",
    icon: NoteAddIcon,
    color: "pending",
    name: "Start Datapak Deployment",
    description: "started a datapak deployment to",
    component: DeployEvent,
  },
  {
    action: "StartProvision",
    icon: PlaylistAddIcon,
    color: "default",
    name: "Start Provision",
    description: "started a provision for",
    component: DeployEvent,
  },
  {
    action: "CreateEnvironment",
    icon: AddIcon,
    color: "default",
    name: "Create Environment",
    description: "created the environment",
    component: null,
  },
  {
    action: "UpdateEnvironment",
    icon: CreateIcon,
    color: "unstable",
    name: "Update Environment",
    description: "updated the configuration for",
    component: DiffEvent,
  },
  {
    action: "DeleteEnvironment",
    icon: RemoveIcon,
    color: "danger",
    name: "Delete Environment",
    description: "deleted the environment",
    component: null,
  },
  {
    action: "CreateApplication",
    icon: AddIcon,
    color: "default",
    name: "Create Application",
    description: "created the application",
    component: null,
  },
  {
    action: "UpdateApplication",
    icon: CreateIcon,
    color: "unstable",
    name: "Update Application",
    description: "updated the configuration for",
    component: DiffEvent,
  },
  {
    action: "DeleteApplication",
    icon: RemoveIcon,
    color: "danger",
    name: "Delete Application",
    description: "deleted the application",
    component: null,
  },
  {
    action: "CreateAccount",
    icon: AddIcon,
    color: "default",
    name: "Create Account",
    description: "created the account",
    component: null,
  },
  {
    action: "UpdateAccount",
    icon: CreateIcon,
    color: "unstable",
    name: "Update Account",
    description: "updated the configuration for",
    component: DiffEvent,
  },
  {
    action: "DeleteAccount",
    icon: RemoveIcon,
    color: "danger",
    name: "Delete Account",
    description: "deleted the account",
    component: null,
  },
  {
    action: "PutPower",
    icon: PowerIcon,
    color: "success",
    name: "Power On",
    description: "powered on",
    component: null,
  },
  {
    action: "DeletePower",
    icon: PowerOffIcon,
    color: "danger",
    name: "Power Off",
    description: "powered off",
    component: null,
  },
  {
    action: "PutTransitVPCConnection",
    icon: PowerIcon,
    color: "success",
    name: "Connect to Transit VPC",
    description: "connected",
    component: null,
  },
  {
    action: "DeleteTransitVPCConnection",
    icon: PowerOffIcon,
    color: "danger",
    name: "Disconnect from Transit VPC",
    description: "disconnected",
    component: null,
  },
  {
    action: "EnableKeepalive",
    icon: CoffeeIcon,
    color: "secondaryAction",
    name: "Enable Keepalive",
    description: "enabled Keepalive for",
    component: null,
  },
  {
    action: "DisableKeepalive",
    icon: CoffeeIcon,
    color: "danger",
    name: "Disable Keepalive",
    description: "disabled Keepalive for",
    component: null,
  },
  {
    action: "CreateGroup",
    icon: AddIcon,
    color: "default",
    name: "Create Group",
    description: "created the group",
    component: null,
  },
  {
    action: "UpdateGroup",
    icon: CreateIcon,
    color: "unstable",
    name: "Update Group",
    description: "updated the configuration for",
    component: null,
  },
  {
    action: "DeleteGroup",
    icon: RemoveIcon,
    color: "danger",
    name: "Delete Group",
    description: "deleted the group",
    component: null,
  },
  {
    action: "PutGroupUser",
    icon: AddIcon,
    color: "default",
    name: "Add User to Group",
    description: "added a user to",
    component: null,
  },
  {
    action: "DeleteGroupUser",
    icon: RemoveIcon,
    color: "danger",
    name: "Remove User from Group",
    description: "removed a user from",
    component: null,
  },
  {
    action: "CreatePermission",
    icon: AddIcon,
    color: "default",
    name: "Create Permission",
    description: "created a permission for",
    component: null,
  },
  {
    action: "UpdatePermission",
    icon: CreateIcon,
    color: "unstable",
    name: "Update Permission",
    description: "updated a permission for",
    component: null,
  },
  {
    action: "DeletePermission",
    icon: RemoveIcon,
    color: "danger",
    name: "Delete Permission",
    description: "deleted a permission for",
    component: null,
  },
]

export const getEventConfig = (event) => {
  let config = eventConfigs.find(x => event.action === x.action)

  if (!config) {
    config = eventConfigs.find(x => x.action === "default")
  }

  config.EventIcon = config.icon
  config.EventComponent = config.component
  config.description = event.description || config.description

  return config
}

export const timestampToId = (ts, short = false) => {
  if (!ts) {
    return ""
  }

  const tsText = ts.toString()

  if (tsText.includes("e-")) {
    return (short) ? tsText.substr(tsText.length - 4) : tsText
  }
  else {
    return (short) ? `e-${tsText.substr(tsText.length - 4)}` : `e-${tsText}`
  }
}

const snakeToTitle = value => {
	return value.replace(/(_)/g, " ")
}

function getFriendlyStatus (name, statusCode) {
	const [first, ...rest] = snakeToTitle(name)

	let result = `${first.toUpperCase()}${rest.join("").toLowerCase()}`

	if (statusCode) {
		result += ` - ${statusCode}`
	}

	return result
}

export function getJobsStatusDetails (status) {
	let statusConfig = null

	if (status) {
		// if (status.state) {
		// 	// try to find config item based on state name instead of status
		// 	statusConfig = window.JobsStatuses.find(x => x.type === "state" && x.stateNames.some(y => y.toUpperCase() === status.state.toUpperCase()))
		//
		// 	if (statusConfig) {
		// 		return { style: statusConfig.style, name: getFriendlyStatus(status.state, status.status) }
		// 	}
		// }

		if (!statusConfig) {
			// try to find config item based on status
			statusConfig = window.JobsStatuses.find(x => x.type === "range" && status.status >= x.statusRange[0] && status.status <= x.statusRange[1])
		}

		if (!statusConfig) {
			// use default config item
			statusConfig = window.JobsStatuses.find(x => x.type === "default")
		}

		return { style: statusConfig.style, name: getFriendlyStatus(statusConfig.name, status.status), description: statusConfig.description }
	}
	else {
		return { style: "initial", name: "initial" }
	}
}
