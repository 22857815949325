const config = require("config")

export function loadUsers (idToken) {
  fetch(`${config.authURI}/users`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
  .then(result => result.json())
  .then(users => {
    this.setState({
      users: users,
      usersLoading: false,
    })
  })
  .catch(() => {
    this.setState({ usersLoading: false, error: true })
  })
}
