const moment = require("moment")

class Dates {
  static formatDynamic (timestamp, utc = false) {
    if (!timestamp) {
      return "invalid timestamp"
    }

    const event = moment(timestamp)

    // get limit for showing relative times
    const relativeLimit = moment().subtract(window.RelativeDateLimitDays, "days")

    if (event.isBefore(relativeLimit)) {
      return Dates.formatFull(timestamp, utc)
    }
    else {
      return Dates.formatRelative(timestamp, utc)
    }
  }

  static formatRelative (timestamp, utc = false) {
    if (!timestamp) {
      return "invalid timestamp"
    }

    const event = (utc) ? moment(timestamp).utc() : moment(timestamp)

    return event.fromNow()
  }

  static formatFull (timestamp, utc = false) {
    if (!timestamp) {
      return "invalid timestamp"
    }

    const event = (utc) ? moment(timestamp).utc() : moment(timestamp)

    return event.format("ddd, MMMM Do YYYY, h:mm:ss A")
  }
}

export default Dates
