const config = require("config")

export function loadEnvironments (idToken, filter = null) {
  fetch(`${config.configURI}/environments/${filter}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
  .then(result => result.json())
  .then(data => {
    this.setState({
      environments: data.environments,
      environmentsLoading: false,
    })
  })
  .catch((e) => {
    console.error(e)
    this.setState({ environmentsLoading: false, error: true })
  })
}
