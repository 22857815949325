const config = require("config")

export function loadEvents (idToken) {
  fetch(`${config.authURI}/audit`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
  .then(result => result.json())
  .then(data => {
    this.setState({
      events: data.events,
      eventsLoading: false,
    })
  })
  .catch(() => {
    this.setState({ eventsLoading: false, error: true })
  })
}
