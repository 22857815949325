import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import { SelectableDataGrid } from "@orchestrate-ui/core"
import ResourceLink from "components/ResourceLink"
import Dates from "utils/Dates"

const styles = theme => ({
  description: {
    padding: `${theme.spacing.unit * 3}px 0`,
  },
})

const columns = [
  { id: "username", label: "Username" },
  { id: "email", label: "Email", options: {
    filter: false,
	}},
  { id: "created", label: "Created", options: {
    filter: false,
	}},
  { id: "updated", label: "Updated", options: {
    filter: false,
	}},
  { id: "groups", label: "Groups (#)", options: {
    filterType: "dropdown",
	}},
  { id: "resources", label: "Resources (#)", options: {
    filterType: "dropdown",
	}},
]

let counter = 0

const Users = (props) => {
  const userGroups = (user) => {
    return props.groups.filter(x => {
      return Boolean(x.users.find(y => y.username === user))
    })
  }

  const userPermissions = (user) => {
    return props.permissions.filter(x => x.principal === user && x.resource !== "*")
  }

  const createTableData = (data) => {
    return {
      id: counter++,
      username: (<ResourceLink resourceKey={data.username} typeOverride="adminUserDetail" sort={data.username} />),
      email: data.email,
      created: (<span sort={data.created_at} title={Dates.formatFull(data.created_at)}>{ Dates.formatDynamic(data.created_at) }</span>),
      updated: (<span sort={data.updated_at} title={Dates.formatFull(data.updated_at)}>{ Dates.formatDynamic(data.updated_at) }</span>),
      groups: userGroups(data.username).length,
      resources: userPermissions(data.username).length,
    }
  }

  const { classes, usersLoading } = props
  const tableData = props.users.map(item => {
    return createTableData(item)
  })

  return (
    <React.Fragment>
      <Typography variant="subtitle1" className={classes.description}>
        Users in this list are created on the first login. To give a user access, you must first add
        that user to this application through AWS SSO.
      </Typography>

      <SelectableDataGrid
        loading={usersLoading}
        tableName={"Users"}
        columns={columns}
        data={tableData}
        staticDefaultTableRows={10}
        readonly
      />
    </React.Fragment>
  )
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
}

export default withStyles(styles)(Users)
