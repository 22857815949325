import jwt from "jsonwebtoken"
import _ from "lodash"
import { UserPreferences } from "@orchestrate-ui/core"

export function isPageLoading (state) {
	return (
		state.isLoadingEnvironments ||
		state.isLoadingCost ||
		state.isLoadingApplications ||
		state.isLoadingAccounts ||
		state.isLoadingEvents ||
		state.isLoadingGroups ||
		state.isLoadingUsers ||
		state.isLoadingPermissions
	)
}

export function getLDUser () {
	const token = UserPreferences.getItem("idToken")

	if (!token) return { key: false }

	return {
		key: getUserId(token),
		name: getUsername(token).toLowerCase(),
		email: getEmail(token).toLowerCase(),
		custom: {
			groups: getGroups(token)
		}
	}
}

export function getUserId (token) {
	if (!token) {
		return false
	}

	const decoded = jwt.decode(token)

	return decoded.sub
}

export function getUsername (token) {
	if (!token) {
		return false
	}

	const decoded = jwt.decode(token)
	const username = decoded.identities[0].userId.split("@")[0]

	return username
}

export function getEmail (token) {
	if (!token) {
		return false
	}

	const decoded = jwt.decode(token)
	const email = decoded.email

	return email
}

export function getGroups (token) {
	if (!token) {
		return []
	}

	const decoded = jwt.decode(token)
	const groups = decoded["cognito:groups"]

	return groups
}

export function isAuthorized (resource, userPermissions, requiredPermissions) {
	if (!userPermissions) return false

	const normalizedResourceKey = (resource) ? resource.toUpperCase() : false

	const global = userPermissions.reduce((result, permission) => {
		if (permission.resource === "*") {
			return result.concat(permission.actions)
		}
		return result
	}, [])

	const specific = userPermissions.reduce((result, permission) => {
		if (normalizedResourceKey && permission.resource.toUpperCase() === normalizedResourceKey) {
			return result.concat(permission.actions)
		}
		return result
	}, [])

	if (global) {
		const dedupedGlobal = [...new Set(global)]

		if (dedupedGlobal.indexOf("FullAccess") !== -1) {
			return true
		}
		if (dedupedGlobal.length && requiredPermissions.length === _.intersection(dedupedGlobal, requiredPermissions).length) {
			return true
		}
	}

	if (specific) {
		const dedupedSpecific = [...new Set(specific)]

		if (dedupedSpecific.indexOf("FullAccess") !== -1) {
			return true
		}
		if (dedupedSpecific.length && requiredPermissions.length === _.intersection(dedupedSpecific, requiredPermissions).length) {
			return true
		}
	}

	return false
}
