/* eslint camelcase: off */

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import WarningRoundedIcon from "@material-ui/icons/WarningRounded"

import { getJobsStatusDetails } from "utils/Events"

const statusIcons = {
	initial: RemoveCircleIcon,
	online: CheckCircleIcon,
	offline: RemoveCircleIcon,
	degraded: WarningRoundedIcon,
	connected: CheckCircleIcon,
	disconnected: RemoveCircleIcon,
}

const blinkAnimationName = "blink"
const blinkAnimationSetting = `${blinkAnimationName} 2s ease-in-out infinite`

const styles = theme => ({
	[`@keyframes ${blinkAnimationName}`]: {
	  "50%": { opacity: 0.1 }
	},
	spaced: {
		padding: theme.spacing.unit,
	},
	dense: {
		padding: "1px 4px !important",
	},
	statusBar: {
	  height: "4px",
	},
	statusCircle: {
		background: "transparent !important",
	},
	statusText: {
		fontWeight: 700,
		background: "transparent !important",
	},
	statusBadge: {
		width: "fit-content",
		borderRadius: 4,
		padding: "6px 20px",
		textTransform: "uppercase",
		fontSize: 12,
		fontWeight: 600,
		textAlign: "center",
		color: "#fff !important",
	},
	default: {
	  background: theme.palette.status.default,
	  color: theme.palette.status.default,
	},
	pending: {
	  background: theme.palette.status.pending,
	  color: theme.palette.status.pending,
	  animation: blinkAnimationSetting,
	},
	pending_approval: {
	  background: theme.palette.status.pending,
	  color: theme.palette.status.pending,
	  animation: blinkAnimationSetting,
	},
	deploying: {
	  background: theme.palette.status.pending,
	  color: theme.palette.status.pending,
	  animation: blinkAnimationSetting,
	},
	provisioning: {
	  background: theme.palette.status.pending,
	  color: theme.palette.status.pending,
	  animation: blinkAnimationSetting,
	},
	starting: {
	  background: theme.palette.status.pending,
	  color: theme.palette.status.pending,
	  animation: blinkAnimationSetting,
	},
	success: {
	  background: theme.palette.status.success,
	  color: theme.palette.status.success,
	},
	connected: {
	  background: theme.palette.status.success,
	  color: theme.palette.status.success,
	},
	danger: {
	  background: theme.palette.status.danger,
	  color: theme.palette.status.danger,
	},
	disconnected: {
	  background: theme.palette.status.danger,
	  color: theme.palette.status.danger,
	},
	failure: {
	  background: theme.palette.status.danger,
	  color: theme.palette.status.danger,
	},
	unstable: {
	  background: theme.palette.status.unstable,
	  color: theme.palette.status.unstable,
	},
})

const Status = (props) => {
	const { classes, variant, size, spaced, dense, status, badgeContent } = props
	const { style, name, description } = getJobsStatusDetails(status)

	const className = badgeContent || style
	const content = badgeContent || name
	const tooltip = description || ""
	const StatusIcon = statusIcons[(content).split("-")[0].trim().toLowerCase()] || React.Fragment

	return (
		<div className={spaced && classes.spaced} {...props}>
			{(variant === "bar") &&
				<div
					data-test-id="bar"
					className={classNames(classes.statusBar, classes[className.toLowerCase()])}
					title={content}
				/>
			}

			{(variant === "circle") &&
				<Tooltip title={tooltip}>
					<StatusIcon
						data-test-id="circle"
						style={size && { fontSize: size }}
						className={classNames(classes[className.toLowerCase()], classes.default, classes.statusCircle)}
					/>
				</Tooltip>
			}

			{(variant === "text") &&
				<Tooltip title={tooltip}>
					<span data-test-id="text" className={classNames(classes[className.toLowerCase()], classes.default, classes.statusText)}>{content}</span>
				</Tooltip>
			}

			{(variant === "badge") && badgeContent &&
        <Typography
					data-test-id="badge"
          variant="caption"
					className={classNames(dense && classes.dense, classes[className.toLowerCase()], classes.default, classes.statusBadge)}
				>
            {content}
        </Typography>
			}
		</div>
	)
}

Status.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(["bar", "circle", "text", "badge"]),
	dense: PropTypes.bool,
	spaced: PropTypes.bool,
	size: PropTypes.number,
	badgeContent: PropTypes.string,
	status: PropTypes.object,
}

Status.defaultProps = {
  variant: "circle",
	dense: false,
	spaced: false,
}

export default withStyles(styles)(Status)
