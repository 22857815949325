import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import Chip from "@material-ui/core/Chip"
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined"
import NotInterestedIcon from "@material-ui/icons/NotInterested"

import { SelectableDataGrid } from "@orchestrate-ui/core"
import ResourceLink from "components/ResourceLink"

import { addUserToGroup, removeUserFromGroup } from "backend/groups"
import { createPermission, updatePermission } from "backend/permissions"

const styles = theme => ({
  content: {
    padding: theme.spacing.unit * 3,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  field: {
    margin: theme.spacing.unit,
  },
  global: {
		display: "flex",
		alignItems: "center",
    marginLeft: theme.spacing.unit * 3,
  },
  check: {
    paddingRight: theme.spacing.unit,
    fontSize: "2rem",
    color: theme.palette.status.success,
  },
  description: {
    paddingLeft: theme.spacing.unit,
  },
  none: {
    paddingRight: theme.spacing.unit,
    fontSize: "2rem",
    color: theme.palette.text.secondary,
  },
  spacer: {
    height: theme.spacing.unit * 2,
  },
  header: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  actions: {
    alignItems: "flex-end",
  },
  formControl: {
    margin: theme.spacing.unit,
    width: 500,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit * 0.25,
  },
})

const columns = {
  members: [
    { id: "username", label: "Username" },
    { id: "email", label: "Email" },
  ],
  permissions: [
    { id: "resourceLink", label: "Resource", options: {
  		filter: false,
  	}},
  	{ id: "resource", label: "Resource", options: {
  		display: false,
  		filterType: "multiselect",
  	}},
    { id: "permissions", label: "Permissions", options: {
  		filter: false,
      customBodyRender (value, tableMeta, updateValue) {
        return (Array.isArray(value)) ? value.join(", ") : value
      }
    }},
  ],
}

let counter = 0
function createTableData (data) {
  return { id: counter++, ...data }
}

class GroupDetail extends React.Component {
  constructor (props) {
    super(props)

    const g = this.groupGlobalPermissions()

    this.state = {
      open: false,
      currentTab: 0,
      globalPermissionsId: g.id,
      globalPermissions: g.actions,
    }

    this.createPermission = createPermission.bind(this)
    this.updatePermission = updatePermission.bind(this)
    this.addUserToGroup = addUserToGroup.bind(this)
    this.removeUserFromGroup = removeUserFromGroup.bind(this)
  }

  findGroup = () => {
    const { groups, match } = this.props
    return (groups) ? groups.find(x => x.name === match.params.key) : { users: [] }
  }

  groupGlobalPermissions = () => {
    const { match, permissions } = this.props
    const p = permissions.filter(x => x.principal === match.params.key && x.resource === "*")

    return (p[0]) ? p[0] : { id: null, actions: [] }
  }

  groupPermissions = () => {
    const { match, permissions } = this.props
    return permissions.filter(x => x.principal === match.params.key && x.resource !== "*")
  }

  addMember = userName => {
    const { idToken, match, users, groups, reload } = this.props
    const group = this.findGroup(groups, match.params.key)
    const fullUsername = users.find(x => x.username === userName).full_username

    this.addUserToGroup(idToken, group.name, fullUsername, reload)
  }

  removeMembers = userObjs => {
    const { idToken, match, users, groups } = this.props
    const group = this.findGroup(groups, match.params.key)

    userObjs.forEach(user => {
      const fullUsername = users.find(x => x.username === user.username).full_username
      this.removeUserFromGroup(idToken, group.name, fullUsername)
    })
  }

  handleChangeTab = (event, value) => {
    this.setState({ currentTab: value })
  }

  handleChange = event => {
    this.setState({ globalPermissions: event.target.value })
  }

  handleOpenDialog = () => {
    this.setState({ open: true })
  }

  handleCloseDialog = () => {
    this.setState({ open: false })
  }

  handleDeletePermission = value => () => {
    const newArray = [].concat(this.state.globalPermissions)
    const deleteIndex = newArray.indexOf(value)
    newArray.splice(deleteIndex, 1)

    this.setState({ globalPermissions: newArray })
  }

  handleSave = () => {
    const { match, idToken, reload } = this.props
    const { globalPermissionsId, globalPermissions } = this.state

    if (globalPermissionsId) {
      this.updatePermission(idToken, globalPermissionsId, {
        principal: match.params.key,
        actions: globalPermissions,
        resource: "*",
      }, reload)
    }
    else {
      this.createPermission(idToken, {
        principal: match.params.key,
        actions: globalPermissions,
        resource: "*",
      }, reload)
    }

    this.handleCloseDialog()
  }

  render () {
    const { classes, users } = this.props
    const { open, currentTab, globalPermissions } = this.state
    const target = this.findGroup() || { name: "", users: [] }

    const tableData = {
      members: target.users.map(item => {
        return createTableData({ username: item.username, email: item.email })
      }),
      permissions: this.groupPermissions().map(item => {
        return {
          id: item.id,
          resource: item.resource,
          resourceLink: (<ResourceLink resourceKey={item.resource} sort={item.resource} />),
          permissions: item.actions,
        }
      }),
    }

    return (
      <React.Fragment>
        <div className={classes.header}>
          <Typography className={classes.title} variant="h5" gutterBottom>
            {target.name}
            <Typography variant="caption">{target.description}</Typography>
          </Typography>
          <div className={classes.actions}>
            <Button variant="outlined" onClick={this.handleOpenDialog}>Edit</Button>
          </div>
        </div>

        <div className={classes.spacer} />

        <Typography gutterBottom>
          This group has the following global permissions
        </Typography>

        {globalPermissions && globalPermissions.map(p => {
          return (
            <Typography key={p} className={classes.global}>
              <CheckCircleOutlinedIcon className={classes.check} />
              {p}
              <Typography variant="caption" className={classes.description}>{window.Claims.find(x => x.name === p).description}</Typography>
            </Typography>
          )
        })}

        {globalPermissions.length === 0 &&
          <Typography className={classes.global}>
            <NotInterestedIcon className={classes.none} />
            None
          </Typography>
        }

        <Tabs
          value={currentTab}
          onChange={this.handleChangeTab}
          textColor="secondary"
        >
          <Tab label="Members" />
          <Tab label="Resource Permissions" />
        </Tabs>

        {currentTab === 0 &&
          <SelectableDataGrid
            tableName={"Members"}
            columns={columns.members}
            data={tableData.members}
            staticDefaultTableRows={5}
            handleAddItem={this.addMember}
            addFormEnum={users && users.map(x => x.username)}
            handleDeleteItems={this.removeMembers}
          />
        }
        {currentTab === 1 &&
          <SelectableDataGrid
            tableName={"Resource Permissions"}
            tableSubheader={"This shows a list of currently active resource permissions. To change these permissions, you must edit the resource config directly."}
            columns={columns.permissions}
            data={tableData.permissions}
            staticDefaultTableRows={5}
            readonly
          />
        }

        <Dialog
          open={open}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle>
            <Typography variant="h6">Edit group: {target.name}</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Global Permissions</InputLabel>
                <Select
                  multiple
                  value={globalPermissions}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} onDelete={this.handleDeletePermission(value)} />
                      ))}
                    </div>
                  )}
                >
                  {window.Claims && window.Claims.map(claim => (
                    <MenuItem key={claim.name} value={claim.name}>
                      <Typography style={{ marginRight: 5 }}>{claim.name}</Typography>
                      <Typography variant="caption">{claim.description}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} autoFocus>
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

GroupDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(GroupDetail)
